<template>
  <template v-if="app">
    <img
      v-if="app.logo"
      class="logo"
      :alt="$t('ui.logo')"
      :src="require(`@/assets/${app.logo}`)"
    >
    <h1 class="title">
      {{ app.title }}
    </h1>
    <p v-html="$t(app.description)" />
    <form
      v-if="showForm"
      @submit.prevent="setNewPass"
    >
      <BaseInput
        id="reg-pass"
        v-model.trim="pass"
        type="password"
        required
        autocomplete="new-password"
        :label="$t('ui.password')"
      />
      <BaseInput
        id="reg-pass-conf"
        v-model.trim="passConfirm"
        type="password"
        required
        autocomplete="new-password"
        :label="$t('ui.repeatPassword')"
      />
      <BaseButton
        type="submit"
        :disabled="isDisabled"
      >
        {{ buttonText }}
      </BaseButton>
    </form>
  </template>
  <BaseInfobox
    v-if="msg"
    :type="infoboxType"
    :remove-margins="!app"
    v-html="$t(msg)"
  />
  <p
    v-if="responseDesc"
    v-html="responseDesc"
  />
</template>

<script>
import BaseInput from '@/components/BaseInput.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseInfobox from '@/components/BaseInfobox.vue'
import NewpassService from '@/services/NewpassService.js'
import { setLocale } from '@/util/i18n.js'

export default {
  name: 'NewpassPage',
  components: {
    BaseInput,
    BaseButton,
    BaseInfobox
  },
  data () {
    return {
      app: null,
      isProcessing: false,
      msg: '',
      infoboxType: '',
      pass: '',
      passConfirm: '',
      responseDesc: ''
    }
  },
  computed: {
    showForm () {
      return !(this.msg && this.infoboxType === 'success')
    },
    /* Boolean-Attribut muss "null" sein, damit es entfernt wird */
    isDisabled () {
      return this.isProcessing ? '' : null
    },
    buttonText () {
      return this.isProcessing ? this.$t('ui.processing') : this.$t('ui.savePassword')
    }
  },
  async created () {
    setLocale(this.$route.query.lang)

    const params = this.$route.params
    if (this.isTokenCorrectlyFormatted(params.resetToken)) {
      const response = await NewpassService.getApp(params.resetToken)
      this.app = response.app
      this.msg = response.errorMsg
      this.infoboxType = response.app ? '' : 'error'
    } else {
      this.msg = this.$t('clientError.tokenImproperlyFormatted')
      this.infoboxType = 'error'
    }
  },
  methods: {
    /* VM Funktionen */
    async setNewPass () {
      if (this.isFormValid()) {
        const creds = {
          token: this.$route.params.resetToken,
          pass: this.pass
        }
        this.isProcessing = true
        const response = await NewpassService.setNewPass(creds)
        this.isProcessing = false
        this.msg = response.msg
        this.infoboxType = response.hasError ? 'error' : 'success'
        this.responseDesc = response.description
      }
    },

    /* Interne Funktionen */
    isTokenCorrectlyFormatted (token) {
      const re = /[A-Za-z0-9]{32}/
      return re.test(token)
    },
    isFormValid () {
      if (this.pass.length < 8) {
        this.infoboxType = 'error'
        this.msg = this.$t('clientError.pwdTooShort')
        return false
      }
      if (this.pass !== this.passConfirm) {
        this.infoboxType = 'error'
        this.msg = this.$t('clientError.pwdsDontMatch')
        return false
      }
      return true
    }
  }
}
</script>
