import http from '@/services'
import i18n from '@/i18n.js'

export default {
  async getApp (token) {
    try {
      const response = await http.get(`newpass.cfc?method=getApp&token=${token}&lang=${i18n.global.locale}`)
      return {
        app: response.data.app,
        errorMsg: ''
      }
    } catch (error) {
      return {
        app: null,
        errorMsg: error?.response?.data?.errorMsg || 'serverError.unknownServerError'
      }
    }
  },
  async setNewPass (creds) {
    const credsWithLang = {
      ...creds,
      lang: i18n.global.locale
    }

    try {
      const response = await http.post('newpass.cfc?method=setNewPass', credsWithLang)
      return {
        hasError: false,
        msg: response.data.msg,
        description: response.data.description
      }
    } catch (error) {
      return {
        hasError: true,
        msg: error?.response?.data?.msg || 'serverError.unknownServerError',
        description: ''
      }
    }
  }
}
